import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../components/breadcrumb"
import Box from "../../components/box"
import Paragraph from "../../components/paragraph"
import Layout from "../../layouts/default"
import PageTitle from "../../components/page-title"
import Grid from "../../components/grid"
import Image from "../../components/image"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"
import Constrain from "../../components/constrain"
import Columns from "../../components/columns"
import Column from "../../components/column"
import Text from "../../components/text"
import logoBBK from "../../images/partner-logos/bbk.png"

const SpurenLinien = () => {
  const data = useStaticQuery(graphql`
    query {
      linien01: file(relativePath: { eq: "neuigkeiten/linien-1.jpg" }) {
        ...mediumImage
      }
      linien02: file(relativePath: { eq: "neuigkeiten/linien-2.jpg" }) {
        ...mediumImage
      }
    }
  `)

  return (
    <Layout backdrop="spuren-linien">
      <Seo title="Auf den Spuren der Linien im Hölderlinturm" description="" />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Auf den Spuren der Linien",
              link: "/neuigkeiten/spuren-der-linien",
            },
          ]}
        />
        <PageTitle>Auf den Spuren der Linien im Hölderlinturm</PageTitle>
        <Stack>
          <Constrain>
            <Paragraph dropcap={true}>
              Ein Schulhalbjahr lang trafen sich 12 Schüler*innen im Alter von 7
              bis 12 Jahren mit der Künstlerin Anett Frey und der
              Museumspädagogin Sylvia Lierau im Hölderlinturm und der
              Museumsvilla des Stadtmuseums. Sie gingen den »Spuren der Linien«
              in Hölderlins Leben, seinen Handschriften und Gedichten nach und
              suchten nach Verbindungen zu ihren eigenen Lebensstationen. In
              Skizzen, Zeichnungen, Radierungen, Holz- und Linolschnitten
              setzten die Jugendlichen eigene Ideen, Wahrnehmungen und Gedanken
              um. Die Ergebnisse sind nun in einer Ausstellung im 1.
              Obergeschoss des Tübinger Stadtmuseums zu sehen.
            </Paragraph>
          </Constrain>
          <Grid columns={[1, 2]} space={[3, 1]}>
            <Image
              image={data.linien01.childImageSharp.gatsbyImageData}
              alt="Bunte Linoldruck-Porträts an einer Wäscheleine."
            />
            <Image
              image={data.linien02.childImageSharp.gatsbyImageData}
              alt="Eine dunkelgrün eingefärbte Holzscheibe. Daneben liegt eine Farbwalze auf einer mit dunkelgrüner Farbe bestrichenen Glasplatte."
            />
          </Grid>

          <Constrain>
            <Stack>
              <Paragraph>
                Begleitend zur Ausstellung bietet die Künstlerin Anett Frey zwei
                Workshops zum Ausprobieren künstlerischer Techniken an.
              </Paragraph>

              <Stack as="ol" space={3} dividers={true}>
                {/* <Text as="div" size={[2, 3, 4]} sans={true}> */}
                {/*   <Columns> */}
                {/*     <Column width="content"> */}
                {/*       <Text as="span" color="whisper"> */}
                {/*         Freitag */}
                {/*       </Text> */}
                {/*     </Column> */}
                {/*     <Column width="content"> */}
                {/*       <Text as="span">18.03.</Text> */}
                {/*     </Column> */}
                {/*     <Column> */}
                {/*       <Text as="strong">Linolschnitt</Text> */}
                {/*     </Column> */}
                {/*     <Column width="content">15-17 Uhr</Column> */}
                {/*   </Columns> */}
                {/* </Text> */}
                <Text as="div" size={[2, 3, 4]} sans={true}>
                  <Columns collapse={[true, false]}>
                    <Column width="130px">
                      <Text as="span" color="whisper">
                        Donnerstag
                      </Text>
                    </Column>
                    <Column width="content">
                      <Text as="span">21.04.</Text>
                    </Column>
                    <Column>
                      <Text as="strong">Linoldruck</Text>
                    </Column>
                    <Column width="content">10-13 Uhr</Column>
                  </Columns>
                </Text>
                <Text as="div" size={[2, 3, 4]} sans={true}>
                  <Columns collapse={[true, false]}>
                    <Column width="130px">
                      <Text as="span" color="whisper">
                        Freitag
                      </Text>
                    </Column>
                    <Column width="content">
                      <Text as="span">22.04.</Text>
                    </Column>
                    <Column>
                      <Text as="strong">Tier-Alphabet</Text>
                    </Column>
                    <Column width="content">10-13 Uhr</Column>
                  </Columns>
                </Text>
              </Stack>
              <Paragraph>
                Die Teilnahme an einem Kurs kostet jeweils 15 Euro.
                Inhaber*innen der KreisBonusCard zahlen Materialkosten in Höhe
                von 3 Euro. Anmeldung über
                <TextLink href="https://app.cituro.com/booking/stadtmuseumtuebingen">
                  mailto:stadtmuseum@tuebingen.de
                </TextLink>
              </Paragraph>
              <Box bg="white" p={6}>
                <img
                  src={logoBBK}
                  alt=""
                  width="992"
                  height="139"
                  style={{ width: "auto", maxHeight: "60px" }}
                />
              </Box>
            </Stack>
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default SpurenLinien
